//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n';
import moment from 'moment';

export default {
  name: 'app-invoice-list-table',
  props: {
    year: {
      type: Number,
    },
    month: {
      type: Number,
    },
    page: {
      type: Number,
    },
  },
  data() {
    return {
      transactionTab: 'trips',
      columnsTrips: [
        {
          name: 'receiptNum',
          required: true,
          label: this.i18n('tables.invoice.receiptNumber'),
          align: 'center',
          field: 'receiptNum',
        },
        {
          name: 'total',
          required: true,
          label: this.i18n('tables.invoice.total'),
          align: 'center',
          field: 'total',
        },
        {
          name: 'paymentMethod',
          required: true,
          label: this.i18n('tables.invoice.paymentMethod'),
          align: 'center',
          field: 'paymentMethod',
        },
        {
          name: 'guideName',
          align: 'center',
          label: this.i18n('tables.invoice.guideName'),
          field: 'guideName',
        },
        {
          name: 'clientName',
          align: 'center',
          label: this.i18n('tables.invoice.clientName'),
          field: 'clientName',
        },
        {
          name: 'createdAt',
          align: 'center',
          label: this.i18n('tables.invoice.invoiceDate'),
          field: 'createdAt',
        },
        {
          name: 'action',
          align: 'center',
          label: this.i18n('tables.invoice.action'),
          field: 'action',
        },
      ],
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      filter: {},
      orderBy: 'createdAt',
    };
  },
  watch: {
    year() {
      this.fetchTransactions();
    },
    month() {
      this.fetchTransactions();
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      doFetchInvoices: 'invoice/doFetch',
    }),
    viewInvoice(id) {
      console.log(id);
      this.$router.push('/invoice/' + id);
    },

    presenterDate(row, fieldName) {
      if (this.language == 'ar') {
        return row[fieldName]
          ? moment(row[fieldName])
              .locale('ar')
              .format('D MMM, YYYY')
          : 'ـــــــ';
      }
      return row[fieldName]
        ? moment(row[fieldName])
            .locale('en')
            .format('D MMM, YYYY')
        : 'ـــــــ';
    },

    async goToPreviousPage() {
      this.filter = {
        month: this.month,
        year: this.year,
      };
      const pagination = {
        sortBy: 'desc',
        limit: this.getItemsPerPage,
        action: 'prev',
        page: this.pageNumber,
      };
      await this.doFetchTripsTransaction({
        filter: this.filter,
        orderBy: this.orderBy,
        pagination,
      });
      this.pageNumber -= 1;
      this.finishTripsTransactionsLoading();
    },
    async loadMore() {
      this.filter = {
        month: this.month,
        year: this.year,
      };
      const pagination = {
        sortBy: 'desc',
        // limit: this.getItemsPerPage,
        limit: this.getItemsPerPage,
        action: 'next',
        page: this.pageNumber,
      };
      await this.doFetchTripsTransaction({
        filter: this.filter,
        orderBy: this.orderBy,
        pagination,
      });
      console.log(this.tripsTransactions);
      this.pageNumber += 1;
      this.finishTripsTransactionsLoading();
    },
    async fetchTransactions() {
      this.filter = {
        month: this.month,
        year: this.year,
      };
      const pagination = {
        sortBy: 'desc',
        limit: this.getItemsPerPage,
      };
      await this.doFetchTripsTransaction({
        filter: this.filter,
        orderBy: this.orderBy,
        pagination,
      });
      console.log(this.tripsTransactions);
      this.finishTripsTransactionsLoading();
    },
  },

  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      invoices: 'invoice/rows',
      loading: 'invoice/loading',
      countTripsTransactions:
        'payment/list/countTripsTransactions',
      loadingTripsTransactions:
        'payment/list/loadingTripsTransactions',
      tripsTransactionsFirstPage:
        'payment/list/tripsTransactionsFirstPage',
      tripsTransactionsLastPage:
        'payment/list/tripsTransactionsLastPage',
    }),
    rows() {
      if (this.transactionTab == 'trips') {
        return this.rowsTrips;
      }
      // return this.rowsWallet
      return this.walletTransactions;
    },
    columns() {
      if (this.transactionTab == 'trips') {
        return this.columnsTrips;
      }
      return this.columnsWallet;
    },

    getItemsPerPage() {
      // const { screen } = this.$q;
      //   if (screen.lt.sm) {
      //       return 4;
      //   }
      //   if (screen.lt.md) {
      //       return 6;
      //   }
      //   if (screen.lt.lg) {
      //       return 9;
      //   }
      //   if (screen.lt.xl) {
      //       return 9;
      //   }
      return 10;
      // return 2;
    },
    pageNumber: {
      get: function () {
        return this.page;
      },
      set: function (value) {
        this.$emit('changePage', value);
      },
    },
    language() {
      return i18n.locale;
    },
  },
  async mounted() {
    await this.doFetchInvoices({
      orderBy: 'createdAt',
      pagination: {
        sortBy: 'desc',
        limit: 10,
      },
    });
  },
};
